import React, {ChangeEvent, FC, Fragment, MouseEvent, useState} from "react";
import cls from "./customFooter.module.scss"
import {IFooter} from "../MainLayout"
import CustomToTop from "./CustomToTop";
import Link from "next/link";
import {domainUrl} from "../../../../helper";
import useSubscription from "../../../../hooks/useSubscription";
import CustomTextField from "../../../../atoms/CWV/CustomTextField";
import {generateTranslateKey} from "../../../../services/utils";
import BuilderSection from "../../../builder/BuilderDataRender";

const CustomFooter: FC<IFooter> = (
    {
        footerCopyRightValue,
        footerPoweredValue,
        showSubscriptionStyle,
        arrFooterTrack,
        footerMenus,
        showFooterEmailStyle,
        showFooterPhoneStyle,
        footerEmailValue,
        telephone,
        showFooterAddressStyle,
        footerAddressValue,
        dbName,
        socialsFooter,
        translationsData,
        logoPath,
        cacheControlVersion,
        showFooterMenuCls,
        showFooterContactCls,
        isMobile,
        themeOptions,
        selectedLocale,
        domain,
        getBuilderData,
        currencies,
        backOrderValue,
        recaptchaActive
    }
) => {
    const [email, SetEmail] = useState<string>("");
    const {message = "", messageClass = "", subscribe} = useSubscription()
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        SetEmail(event.target.value);
    };

    const handlerClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        subscribe(email)
    };

    const smallPassHelpHandle = {
        "true": (
            <small id="passwordHelp" className={messageClass as string}>
                {translationsData?.[generateTranslateKey(message)]}
            </small>
        ),
        "false": null
    }

    const showFooterText = {
        "true": (
            <div className={cls.copyright}>
                <div>{footerCopyRightValue}</div>
                <div dangerouslySetInnerHTML={{__html: footerPoweredValue}}/>
            </div>
        ),
        "false": null
    }
    const FooterContentHandle = () => {
        //show footer from static store
        if (typeof themeOptions?.show_footer !== "boolean" || themeOptions?.show_footer || isMobile) {
            return (
                <div className={`${cls["footer-container"]} container`}>
                    <div className={cls["footer-body"]}>
                        <div className={cls["footer-main-info"]}>
                            <div>
                                <div className={cls.footer_logo}>
                                    <Link href="/" prefetch={false}>
                                        <a>
                                            <img
                                                src={domainUrl(`${dbName}/${logoPath}?v=${cacheControlVersion}`)}
                                                alt="Logo"
                                                // loading="eager"
                                                loading="lazy"
                                                width="200"
                                                height="45"
                                            />
                                        </a>
                                    </Link>
                                </div>
                                {translationsData.FooterText !== "empty" ?
                                    <div className={cls['footer_text']}>
                                        <p>{translationsData.FooterText}</p>
                                    </div>
                                    : null
                                }
                            </div>
                            {arrFooterTrack?.map(({href, id}: { href: string, id: string }, index: number) => (
                                <div key={index} className={cls["trackingNumber-fms"]}>
                                    <Link href={href} prefetch={false}><a>{id}</a></Link>
                                </div>
                            ))}
                        </div>
                        <div className={`${cls["footer-first-column"]} ${cls[showFooterMenuCls]}`}>
                            <h3 className={cls["footer-newsletter__title"]}>{translationsData.FooterMenu}</h3>
                            {footerMenus?.map(({
                                                   url_key = "",
                                                   name: menuName,
                                                   target,
                                               }, index: number) => {
                                return (
                                    <div key={index} className={cls["footerLinks-fms"]}>
                                        <Link href={url_key}
                                              prefetch={false}>
                                            <a {...target}>{menuName}</a>
                                        </Link>
                                    </div>
                                )
                            })}
                        </div>
                        <div className={`${cls["footer-newsletter-hide"]} ${cls[showFooterContactCls]}`}>
                            <h3 className={cls["footer-newsletter__title"]}>{translationsData.FooterContacts}</h3>
                            <div className={`${cls["footer-newsletter__social-links"]} ${cls["footer-contacts"]}`}>
                                <ul className={cls["footer-contacts__contacts"]}>
                                    <li className={cls.contactsAddress_list}>
                                        <div className={cls.contactsAddress} style={{display: showFooterEmailStyle}}>
                                            {footerEmailValue}
                                        </div>
                                        <div className={cls.contactsAddress} style={{display: showFooterPhoneStyle}}>
                                            <Link href={`tel:${telephone}`} prefetch={false}><a>{telephone}</a></Link>
                                        </div>
                                        <div className={cls.contactsAddress}>
                                            <div style={{display: showFooterAddressStyle}}>{footerAddressValue}</div>
                                        </div>
                                        <div>
                                            <div
                                                className={`${cls["footer-newsletter__social-links"]} ${cls["social-links"]} ${cls["social-links--shape--circle"]}`}>
                                                <ul className={cls["social-links__list"]}>
                                                    {socialsFooter?.map(({
                                                                             name: socialName,
                                                                             icon: socialIcon,
                                                                             link: socialLink
                                                                         }, index) => (
                                                        <li className={cls["social-links__item"]} key={index}>
                                                            <Link
                                                                className={`${cls["social-links__link"]} ${cls["social-link-color"]}`}
                                                                href={socialLink}
                                                                prefetch={false}
                                                                style={{
                                                                    position: "relative",
                                                                    width: "100%",
                                                                    height: "100%"
                                                                }}
                                                            >
                                                                <a target="_blank">
                                                                    <img
                                                                        src={domainUrl(`${dbName}/storage/${socialIcon}`)}
                                                                        alt={socialName}
                                                                        loading="lazy"
                                                                        width="30"
                                                                        height="30"
                                                                    />
                                                                </a>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={cls["newsletter-block"]}
                             style={{display: showSubscriptionStyle}}
                        >
                            <div className={cls["footer-newsletter-container"]}>
                                <h3 className={cls["footer-newsletter__title"]}>
                                    {translationsData?.Subscribe}
                                </h3>
                                <form action="" className={cls["footer-newsletter__form"]}>
                                    <CustomTextField
                                        className={`${cls.custom_input_field} `}
                                        id="footer_news_email__id"
                                        type="email"
                                        name="email"
                                        autoComplete="off"
                                        value={email}
                                        onChange={handleChange}
                                        placeholder={translationsData?.Email}
                                    />
                                    {smallPassHelpHandle[`${!!message}`]}
                                    <div className="btn-wrapper">
                                        <button
                                            onClick={handlerClick}
                                            type="submit"
                                            className={`${cls["footer-newsletter__form-button"]} btn`}
                                        >
                                            {translationsData?.SubscribeButton}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    {showFooterText[`${!!footerCopyRightValue || !!footerPoweredValue}`]}
                </div>
            )
        } else {
            const [{
                code: selectedCurrCode = "",
                symbol: selectedCurrSymbol = "",
                exchange_rate: {rate: selectedCurrRate = "1"} = {},
            } = {}] = currencies
            const builderProps = {
                getBuilderData,
                domain,
                dbName,
                translationsData,
                selectedLocale,
                currencies,
                backOrderValue,
                selectedCurrItems: {selectedCurrCode, selectedCurrRate, selectedCurrSymbol},
                isMobile,
                recaptchaActive
            }
            return <BuilderSection tab={5}  {...builderProps} />
        }
    }
    return (
        <div className={cls["site-footer"]} style={themeOptions?.show_footer === false && !isMobile ? {padding: "unset"} : {}}>
            <FooterContentHandle/>
            <CustomToTop/>
        </div>
    );
};

export default CustomFooter;
