import cls from "./customHeader.module.scss"
import React, {FC, Fragment, JSX, useEffect, useRef, useState} from "react";
import {IFirstHeader} from "../MainLayout";
import Link from "next/link";
import {domainUrl} from "../../../../helper";
import Script from "next/script";
import {useRouter} from "next/router";
import CustomItemList from "./CustomItemList";
import {ArrowRoundedDown9x6Svg, Cross20Svg, VektorMenu, DropMenusSVG} from "../../../../svg";
import {mobileMenuOpen} from "../../../../store/mobile-menu";
import {useDispatch, useSelector} from "react-redux";
import {IState} from "../../../../types/state";

const showPost: (arg: string) => Record<string, JSX.Element> = (title) => {
    return {
        BlogActive: <div className={cls.header_nav_menu_item}>
            <Link href="/posts" prefetch={true}>{title}</Link>
        </div>,
        BlogInActive: <></>
    }
};

const schemaSiteHeader = (dbName: string, asPath: string) => {
    return {
        "@context": `https://schema.org/`,
        "@type": "ItemList",
        "name": "Header Items",
        "description": "contact, change currency and language",
        "itemListElement": [
            {
                "@type": "ListItem",
                "position": 1,
                "name": "Telephone"
            },
            {
                "@type": "ListItem",
                "position": 2,
                "name": "Currency"
            },
            {
                "@type": "ListItem",
                "position": 3,
                "name": "Language"
            },
        ],
        url: domainUrl(dbName + asPath)
    }
}

const FirstSection: FC<IFirstHeader> = (
    {
        categories,
        logoPath,
        dbName,
        menus,
        hasBlog,
        selectedLocaleImg,
        translationsData,
        locales = [],
        cacheControlVersion
    }
) => {
    const router = useRouter()
    const {asPath} = router
    const schemaHeader = schemaSiteHeader(dbName, asPath)
    const [
        {langOpened},
        setOpenLang
    ] = useState({langOpened: false, langClassName: ""})
    const mobileMenu = useSelector((state: IState) => state.mobileMenu.open)
    const langRef = useRef<any>(null)
    const dispatch = useDispatch()
    const dropDownRef = useRef<any>(null);
    const containerRef = useRef<any>(null);
    const [width, setWidth] = useState(containerRef);
    const [dropOpen, setDropOpen] = useState<boolean>(false);
    const [openMenus, setOpenMenus] = useState<number[]>([]);
    const [menusRowWidth, setMenusRowWidth] = useState(991);
    const gap = 42;
    let isOverflow = false;
    const letterSize = 8.5; // average number of each letter
    const activeBlog = hasBlog === 'BlogActive';
    let menusWidth = activeBlog ? (translationsData?.Posts.length * letterSize + 60) : 60;
    const handleClassTypes = {"false": ["", "menu-none"], "true": ["rotT_fms", "menu-block"]};

    useEffect(() => {
        setMenusRowWidth(containerRef.current?.offsetWidth)
    }, [containerRef.current?.offsetWidth]);

    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            if (langRef && langRef.current && !langRef.current.contains(e.target)) {
                setOpenLang({langOpened: false, langClassName: ""})
            }

            if (dropDownRef.current && !dropDownRef.current.contains(e.target as Node)) {
                setDropOpen(false);
            }
        }

        window.addEventListener("mousedown", handleClick)

        return () => window.removeEventListener("mousedown", handleClick)
    }, [langRef, dropDownRef])

    useEffect(() => {
        const handleResize = () => {
            setWidth(containerRef.current.clientWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener('resize', handleResize);

    }, [menus, width]);

    const handleLangClick = () => {
        setOpenLang(({langOpened: prevOpen}) => {
            return {
                langOpened: !prevOpen,
                langClassName: handleClassTypes[`${!prevOpen}`][0]
            }
        })
    }

    const toggleDrop = () => {
        setDropOpen(prevState => !prevState)
    }

    const toggleSub = (id: number) => {
        if (openMenus.some(subId => subId === id)) {
            setOpenMenus((prevState) => prevState.filter(subId => subId !== id))
        } else {
            setOpenMenus(prevState => [...prevState, id])
        }
    }

    const mobileMenuIcon = {
        true: <VektorMenu className={cls.menu_icon}/>,
        false: <Cross20Svg/>
    }

    const handleLangItemClick = (localeCode: string) => {
        router.push(asPath, asPath, {locale: localeCode}).then(r => r);
    }

    const hasDropDown = {
        true: <div className={cls[handleClassTypes[`${langOpened}`][1]]}>
            <div className={cls.menu}>
                {locales?.map(({code, id, locale_image}) => {
                    return <div key={id} className={cls.menu_item}
                                onClick={() => handleLangItemClick(code)}>
                        <div className={cls.menu__icon}>
                            <img
                                src={domainUrl(`${dbName}${locale_image}`)}
                                alt="Language"
                                width="30"
                                height="24"
                                loading="lazy"
                            />
                        </div>
                    </div>
                })}
            </div>
        </div>,
        false: null
    }

    const renderMenus = () => {
        let visibleCollection: JSX.Element[] = [];
        let dropdownCollection: JSX.Element[] = [];

        menus.forEach((item: any, index: number) => {
            const {name = "", id, children = [], url_key = "", target} = item;
            const existThisMenuId = openMenus.some(elem => elem === id)

            let arrow = null;
            let menu = null;
            let menu2 = null;


            if (children.length) {
                arrow = <div
                    className={`${cls.wrapper__bttn} ${existThisMenuId && cls.closed}`}>
                    <ArrowRoundedDown9x6Svg/>
                </div>
                menu = <CustomItemList items={children} openMenus={openMenus} toggleSub={toggleSub}/>

                menu2 = <div className={`${cls.deep__submenu} ${existThisMenuId && cls.opened__drop}`}>
                    <CustomItemList items={children} openMenus={openMenus} toggleSub={toggleSub}/>
                </div>
            }

            if (item && item.name) {
                const itemArrow = item.children.length ? 15 : 0
                const itemWidth = item.name.length * letterSize + itemArrow + gap;

                const visibleItem = <div key={index} className={cls.header_nav_menu_item}>
                    <Link href={url_key} prefetch={true}>
                        <a {...target}>{name} {arrow}</a>
                    </Link>
                    {menu}
                </div>

                const dropdownItem =
                    <div
                        key={index}
                        className={cls.header_nav_menu_item}
                        onClick={() => toggleSub(id as number)}
                    >
                        <div className={cls.menu_item__container}>
                            <button
                                className={`${cls.link__button} ${menu && cls.only__link}`}
                                onClick={e => e.stopPropagation()}
                            >
                                <Link href={url_key} prefetch={true}>
                                    <a
                                        {...target}
                                    >
                                        {name}
                                    </a>
                                </Link>
                            </button>
                            {arrow}
                        </div>
                        {menu2}
                    </div>

                if (isOverflow || menusWidth + itemWidth > menusRowWidth) {
                    isOverflow = true;
                    dropdownCollection.push(dropdownItem);
                } else {
                    menusWidth += itemWidth;
                    visibleCollection.push(visibleItem);
                }
            }
        })

        return (
            <>
                {visibleCollection}
                {showPost(translationsData?.Posts)[hasBlog]}
                {!!dropdownCollection.length &&
                    <div ref={dropDownRef} className={cls.dropMenus__container}>
                        <button
                            className={cls.wrapper__bttn}
                            onClick={toggleDrop}>
                            <DropMenusSVG/>
                        </button>
                        <div className={`${cls.dropMenus__body} ${dropOpen ? cls.opened__drop : null}`}>
                            {dropdownCollection}
                        </div>
                    </div>
                }
            </>
        )
    }

    return (
        <Fragment>
            <Script
                id={schemaHeader.name}
                type="application/ld+json"
                dangerouslySetInnerHTML={{__html: JSON.stringify(schemaHeader)}}
                defer={true}
            />
            <div className="container">
                <div className={cls.first_section}>
                    <button
                        type="button"
                        onClick={() => dispatch(mobileMenuOpen())}
                        className={cls[`mobile-header__menu-button`]}
                        aria-label="MobileHeaderBtn"
                    >
                        {mobileMenuIcon[`${!mobileMenu}`]}
                    </button>
                    <div className={cls.logo_div}>
                        <Link href="/" prefetch={true}>
                            <a>
                                <img
                                    src={domainUrl(`${dbName}/${logoPath}?v=${cacheControlVersion}`)}
                                    alt="Logo"
                                    // loading="eager"
                                    loading="lazy"
                                    width="200"
                                    height="45"
                                />
                            </a>
                        </Link>
                    </div>
                    <div
                        id="forLanguage"
                        className={cls["language-button"]}
                        onClick={handleLangClick}
                        ref={langRef}
                    >
                        <img
                            src={domainUrl(`${dbName}${selectedLocaleImg}`)}
                            alt="Language"
                            // loading="eager"
                            loading="lazy"
                            width="30"
                            height="24"
                        />
                        {hasDropDown[`${locales.length > 0}`]}
                    </div>
                    <div className={cls["nav-panel__departments"]}>
                        <button
                            type="button"
                            className={cls["mobile-header__menu-button"]}
                            onClick={() => dispatch(mobileMenuOpen())}
                            aria-label="MobileHeaderBtn"
                        >
                            {mobileMenuIcon[`${!mobileMenu}`]}
                            <span className={cls["category-title-fms"]}>
                                {categories?.[0]?.name}
                            </span>
                        </button>
                    </div>
                    <div className={cls.header_nav_menu}>
                        <div ref={containerRef} className={cls.header_nav_menu}>
                            {renderMenus()}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

export default FirstSection;
